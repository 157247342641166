.slick-list {
  margin: 0 -12px;
}

.slick-next,
.slick-arrow {
  color: #000;
  background-color: #000;
}

.category .slick-slide>div {
  padding: 0 12px;
}

.ant-btn-primary {
  background-color: #1677ff;
}

button {
  cursor: pointer;
}

@tailwind base;
@tailwind components;
@tailwind utilities;